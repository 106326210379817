import React from "react";
import Content from '../views/BookDemo';

export default ({location}) => (
  <Content location={location} seo={{
    title: 'Tilmeld dig til digitale tjenester for køretøjer',
    lang: 'da-DK',
    description: 'Vælg hvilke tjenester du gerne vil se i aktion: webbaseret flådestyring eller fjernmonitorering af køretøjer.',
    meta: [{
      name: 'keywords',
      content: 'Tjenester for køretøjer'
    }]
  }}/>
);